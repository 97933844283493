
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        value: {
            type: String,
            required: true,
        },
    },
})
export default class Transaction extends Vue {
    value!: string
    url = '#'

    async created() {
        this.url = await this.$filters.toBlockExplorer(this.value, 'tx')
    }

    get elipsedTx() {
        return this.value?.substr(0, 10) + '...' + this.value?.substr(this.value.length-6, this.value.length);
    }
}
