export default {
  "actions": {
    "connect_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect Wallet"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])}
  },
  "status": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loading..."])},
    "connecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecting to your wallet..."])},
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract has expired."])},
    "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract is paused."])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait"])}
  },
  "feedback": {
    "user_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User rejected. Check your wallet and try again."])},
    "rpc_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RPC error. Reload page and try again."])},
    "pending_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There's a pending transaction in your wallet. Check your wallet and try again."])},
    "not_enough_balance_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough balance in your wallet."])},
    "not_enough_balance_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough tokens available for you."])},
    "not_in_whitelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your wallet is not in our whitelist."])},
    "invalid_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid amount. You can only buy integer parts of this token."])},
    "allowance_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for 'approve' transaction to be confirmed."])},
    "allowance_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve transaction:"])},
    "buy_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for 'buy' transaction to be confirmed."])},
    "buy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy transaction:"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All transactions confirmed!"])},
    "error": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Check the last transaction in your wallet."])}
    }
  },
  "buy_token": {
    "price_per_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per token"])},
    "you_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You pay"])},
    "you_get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You get"])},
    "you_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved to spend"])},
    "your_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your balance"])},
    "available_to_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available to buy"])},
    "whitelisted_for_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whitelisted for you"])},
    "ends_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ends at"])},
    "approve_and_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve & Buy"])},
    "khiza_on_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens on Wallet"])},
    "fill_all_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill all"])}
  }
}