import { BigNumberish } from "ethers";

export type IparseEther = (v : BigNumberish|null, relevantDecimals: number) => string;
const khizaTokenDecimals = Number(process.env.VUE_APP_KHIZA_TOKEN_DECIMALS!)

export default function parseEther(v : BigNumberish|null, relevantDecimals = 2): string {
    const unit = v?.toString().slice(0, -khizaTokenDecimals) || '0'
    const decimals = v?.toString().slice(-khizaTokenDecimals, -(khizaTokenDecimals - relevantDecimals))
    const toNumber = Number(unit + '.' + decimals)
    return v ? toNumber.toLocaleString() : '';
}