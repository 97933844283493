import { App } from "vue";
import { VendorType, ERC20ContractType } from "@/lib/ContractTypes";
import { ERC20Abi, VendorAbi } from "@/lib/ABIs";
import { ContractReceipt, ContractTransaction, ethers } from "ethers";
import $store, { IModalInfo } from "@/store";
import $router from "@/router";

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/html";
import {
  configureChains,
  createClient,
  disconnect,
  fetchSigner,
  FetchSignerResult,
  getAccount,
  GetAccountResult,
  getNetwork,
  GetNetworkResult,
  getProvider,
  GetProviderResult,
  SwitchChainNotSupportedError,
  switchNetwork,
  watchAccount,
  watchNetwork,
  watchProvider,
  watchSigner,
} from "@wagmi/core";
import { polygon, polygonMumbai } from "@wagmi/core/chains";

const chains = [polygon, polygonMumbai];
const projectId = process.env.VUE_APP_WALLET_CONNECT_PROJECT_ID!;

const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createClient({
  autoConnect: false,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);
const web3modal = new Web3Modal({ projectId }, ethereumClient);
// web3modal.setDefaultChain(polygon)

export interface IWeb3PluginState {
  vendorContract: VendorType | null;
  khizaTokenContract: ERC20ContractType | null;
  acceptedTokenContract: ERC20ContractType | null;
}

export interface IWeb3Plugin extends IWeb3PluginState {
  isInitialized: boolean;

  setVendorContract: (v: VendorType | null) => void;
  setKhizaTokenContract: (v: ERC20ContractType | null) => void;
  setAcceptedTokenContract: (v: ERC20ContractType) => void;

  connectWallet: () => Promise<void>;
  disconnect: () => Promise<void>;
  initContracts: () => Promise<void>;

  waitFor(tx: ContractTransaction, obj: IModalInfo): Promise<ContractReceipt>;
}

const state: IWeb3PluginState = {
  vendorContract: null,
  khizaTokenContract: null,
  acceptedTokenContract: null,
};

class Web3Plugin {
  isInitialized = false;

  constructor() {
    this.registerWalletEvents();
    // this.initProvider();
  }

  get vendorContract(): VendorType | null {
    return state.vendorContract;
  }
  get khizaTokenContract(): ERC20ContractType | null {
    return state.khizaTokenContract;
  }
  get acceptedTokenContract(): ERC20ContractType | null {
    return state.acceptedTokenContract;
  }

  setVendorContract(v: VendorType | null) {
    state.vendorContract = v;
  }
  setKhizaTokenContract(v: ERC20ContractType | null) {
    state.khizaTokenContract = v;
  }
  setAcceptedTokenContract(v: ERC20ContractType | null) {
    state.acceptedTokenContract = v;
  }

  async connectWallet(): Promise<void> {
    console.log('connectWallet()')
    await web3modal.openModal({ route: "ConnectWallet" });
    console.log('after')
    if (this.isInitialized) {
        console.log('is initialized')
        await web3modal.closeModal();
    }
  }

  async disconnect() {
    await disconnect();
    await this.onWalletDisconnected()
  }

  private async onWalletDisconnected() {
    $store.commit("SET_WALLET_ADDRESS", null);
    $store.commit("SET_NETWORK", null);
    $store.commit("SET_KHIZA_TOKEN_SYMBOL", null);
    $store.commit("SET_ACCEPTED_TOKEN_SYMBOL", null);
    this.setVendorContract(null);
    this.setKhizaTokenContract(null);
    this.setAcceptedTokenContract(null);
    $router.push({ name: "connect_wallet", params: { t: Date.now() } });
  }

  private async onWalletConnected() {
    const networkId = (await getNetwork()).chain?.id;
    $store.commit("SET_NETWORK", networkId);
    $store.commit("SET_WALLET_ADDRESS", (await getAccount()).address);
    await this.initContracts();
    console.log('push home')
    await $router.push({ name: "home", params: { t: Date.now() } });
    this.isInitialized = true;
  }

  /**
   * Inicializa o provider de acordo com as chaves no ambiente.
   *
   * @returns Retorna o provider inicializado.
   */
  // async initProvider(): Promise<ethers.providers.Provider> {
  //     console.log('initProvider()')
  //     const alchemyProvider = new providers.AlchemyProvider(
  //         (await getNetwork()).chain?.id,
  //         process.env.VUE_APP_ALCHEMY_API_KEY
  //     );
  //     return alchemyProvider;
  // }

  /**
   * Requisita uma alteração de rede para a carteira.
   *
   * @param chainId Id da rede para a qual a carteira deve ser trocada.
   * @throws SwitchChainNotSupportedError Caso a carteira não ofereça suporte para trocar de rede.
   * @throws Outros erros
   */
  async requestSwitchNetwork(chainId: number) {
    // Pedir para trocar para uma rede suportada caso a wallet permita isso
    try {
      await switchNetwork({ chainId });

      // TODO: Tratar caso o usuário não tenha a rede cadastrada
    } catch (error) {
      if (error instanceof SwitchChainNotSupportedError) {
        // Neste caso a wallet não oferece suporte para pedir para trocar de rede
        // TODO: Exibir modal
      }
      // Outros erros sobem para o componente que chamou o plugin
      else throw error;
    }
  }

  async initContracts(): Promise<void> {
    console.log("initContracts()");
    const network = await getNetwork();
    const networkName =
      network.chain!.id == 80001
        ? "MUMBAI"
        : network.chain!.id == 137
        ? "POLYGON"
        : null;
    if (!networkName) {
      console.error("Trying to init contracts on an unsupported network!");
      return;
    }

    const contractAddress =
      process.env[`VUE_APP_${networkName}_CONTRACT_ADDRESS`]!;
    const provider = await getProvider();

    if ((await provider.getCode(contractAddress)) === "0x") {
      throw new Error(
        "Could not find the contract, are you connected to the right chain?"
      );
    }

    const signer = (await fetchSigner()) as ethers.Signer;

    console.log('signer', signer)
    console.log('contractAddress', contractAddress)

    const vendorContract = new ethers.Contract(
      contractAddress,
      VendorAbi,
      signer
    ) as unknown as VendorType;

    console.log('vendorContract', vendorContract)

    this.setVendorContract(vendorContract);

    const khizaTokenAddress = await vendorContract.connect(provider).tokenToSell();
    const khizaToken = new ethers.Contract(
      khizaTokenAddress,
      ERC20Abi,
      signer
    ) as unknown as ERC20ContractType;

    this.setKhizaTokenContract(khizaToken);

    $store.commit("SET_KHIZA_TOKEN_SYMBOL", await khizaToken.symbol());

    const acceptedTokenAddress = await vendorContract.tokenToPay();
    const acceptedToken = new ethers.Contract(
      acceptedTokenAddress,
      ERC20Abi,
      signer
    ) as unknown as ERC20ContractType;

    this.setAcceptedTokenContract(acceptedToken);

    $store.commit("SET_ACCEPTED_TOKEN_SYMBOL", await acceptedToken.symbol());
  }

  private async registerWalletEvents() {
    const networkId = (await getNetwork()).chain?.id;
    const chainArgs = { chainId: networkId };
    $store.commit("SET_NETWORK", networkId);

    watchAccount(this.onAccountChanged.bind(this));
    watchNetwork(this.onNetworkChanged.bind(this));
    watchProvider(chainArgs, this.onProviderChanged.bind(this));
    watchSigner(chainArgs, this.onSignerChanged.bind(this));
  }

  private async onProviderChanged(provider: GetProviderResult) {
    // console.log("onProviderChanged", provider);
    // this.provider = provider
  }

  private async onSignerChanged(signer: FetchSignerResult) {
    // console.log("onSignerChanged", signer);
  }

  private async onAccountChanged(account: GetAccountResult) {
    // console.log("onAccountChanged", account, account.address);

    // this.account = account
    // if (account.address) {
    //   await this.onWalletConnected();
    // } else if (!account.isConnecting) {
    //   await this.onWalletDisconnected();
    // }
  }

  private async onNetworkChanged(network: GetNetworkResult) {
    console.log("onNetworkChanged", network);
    const isNetworkSupported = !(await getNetwork()).chain?.unsupported;
    if (isNetworkSupported) {
      await this.onWalletDisconnected();
      await this.onWalletConnected();
      return;
    }

    alert("Unsupported network! Switching to Polygon Mainnet.");
    await this.requestSwitchNetwork(polygon.id);
  }

  public async waitFor(
    tx: ContractTransaction,
    obj: IModalInfo
  ): Promise<ContractReceipt> {
    $store.commit("SET_MODAL", {
      ...obj,
      tx: tx.hash,
    });
    const receipt = await tx.wait().finally(() => {
      $store.commit("SET_MODAL", false);
    });
    return receipt;
  }
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$web3 = new Web3Plugin();
  },
};
