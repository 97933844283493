
import { Options, Vue } from 'vue-class-component';
import { IModalInfo } from '../store';
import Transaction from './Transaction.vue';

@Options({
    components: {
        Transaction,
    },
})
export default class WaitForTransactionModal extends Vue {
    get isModalOpen(): boolean {
        return this.modalInfo !== false
    }

    get modalInfo(): IModalInfo|boolean {
        return this.$store.getters['modalInfo']
    }

    get dynamicClass() {
        return {
            'is-active': this.isModalOpen
        }
    }
}
