
import { Options, Vue } from 'vue-class-component';
import Address from '@/components/Address.vue';
import WaitForTransactionModal from './components/WaitForTransactionModal.vue';

@Options({
  components: {
    Address, WaitForTransactionModal
  }
})
export default class App extends Vue {
  async logout() {
    await this.$web3.disconnect()
  }
}
