import { App } from 'vue';
import parseUSDC, { IparseUSDC } from "./parseUSDC";
import parseEther, { IparseEther } from "./parseEther";
import datetime, { Idatetime } from "./datetime";
import toBlockExplorer, { ItoBlockExplorer } from "./toBlockExplorer";

export interface IFilters {
    parseUSDC: IparseUSDC,
    parseEther: IparseEther,
    datetime: Idatetime,
    toBlockExplorer: ItoBlockExplorer,
}

export default {
    install: (app: App) => {
        app.config.globalProperties.$filters = {
            parseUSDC,
            parseEther,
            datetime,
            toBlockExplorer,
        }
    }
}