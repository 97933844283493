import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import filters from './plugins/filters'
import web3Plugin from './plugins/web3'
import i18n from './i18n'
import './styles/index.scss'

createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .use(filters)
    .use(web3Plugin)
    .mount('#app')