import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    target: "_blank",
    href: _ctx.url
  }, [
    _createElementVNode("small", null, _toDisplayString(_ctx.elipsedTx), 1)
  ], 8, _hoisted_1))
}