import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import $store from '@/store'

async function requireWallet() {
  if (!$store.getters['isWalletConnected']) {
    console.log('[router] redirecting to connect_wallet')
    return { name: 'connect_wallet' }
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    // component: HomeView,
    beforeEnter: [requireWallet],
    redirect: '/buy',
  },
  {
    path: '/buy',
    name: 'Buy',
    component: () => import(/* webpackChunkName: "connect_wallet" */ '../views/BuyTokensView.vue'),
    beforeEnter: [requireWallet],
  },
  {
    path: '/connect-wallet',
    name: 'connect_wallet',
    component: () => import(/* webpackChunkName: "connect_wallet" */ '../views/ConnectWallet.vue'),
  },
  // {
  //   path: '/admin',
  //   name: 'admin',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AdminView.vue'),
  //   beforeEnter: [requireWallet],
  // },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  console.log(`[router] ${String(from.name)} -> ${String(to.name)}`)
})

export default router
