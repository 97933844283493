import { BigNumberish } from "ethers";

export type IparseUSDC = (v : BigNumberish|null) => string;
const USDCDecimals = Number(process.env.VUE_APP_ACCEPTED_TOKEN_DECIMALS!)
const relevantDecimals = 2

export default function parseUSDC(v : BigNumberish|null): string {
    const unit = v?.toString().slice(0, -USDCDecimals) || '0'
    const decimals = v?.toString().slice(-USDCDecimals, -(USDCDecimals - relevantDecimals))
    const toNumber = Number(unit + '.' + decimals?.padStart(2, '0'))
    return v ? toNumber.toLocaleString() : '';
}