
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        value: {
            type: String,
            required: true,
        },
    },
})
export default class Address extends Vue {
    value!: string
    url = '#'

    async created() {
        this.url = await this.$filters.toBlockExplorer(this.value, 'address')
    }
}
