import { createStore } from 'vuex';

export type IModalInfo = {
  title  ?: string,
  message?: string,
  tx     ?: string,
  html   ?: string,
}

export interface State {
  walletAddress      : string|null;
  isLoadingContract  : boolean;
  network            : number|null;
  acceptedTokenSymbol: string|null;
  khizaTokenSymbol   : string|null;
  modalInfo          : false|IModalInfo;
}

export default createStore<State>({
  state: {
    walletAddress      : null,
    isLoadingContract  : false,
    network            : null,
    acceptedTokenSymbol: null,
    khizaTokenSymbol   : null,
    modalInfo          : false,
  },
  getters: {
    isWalletConnected(state): boolean {
      return !!state.walletAddress
    },
    walletAddress(state): string|null {
      return state.walletAddress
    },
    isLoadingContract(state): string|null {
      return state.walletAddress
    },
    network(state): number|null {
      return state.network
    },
    acceptedTokenSymbol(state): string|null {
      return state.acceptedTokenSymbol
    },
    khizaTokenSymbol(state): string|null {
      return state.khizaTokenSymbol
    },
    isTestnet(state): boolean {
      return String(state.network) == '80001'
    },
    modalInfo(state): false|IModalInfo {
      return state.modalInfo
    },
  },
  mutations: {
    SET_WALLET_ADDRESS(state, walletAddress: string) {
      state.walletAddress = walletAddress
    },
    SET_IS_LOADING_CONTRACT(state, isLoading: boolean) {
      state.isLoadingContract = isLoading
    },
    SET_NETWORK(state, network: number) {
      state.network = network
    },
    SET_ACCEPTED_TOKEN_SYMBOL(state, symbol: string) {
      state.acceptedTokenSymbol = symbol
    },
    SET_KHIZA_TOKEN_SYMBOL(state, symbol: string) {
      state.khizaTokenSymbol = symbol
    },
    SET_MODAL(state, modalInfo: false|IModalInfo) {
      state.modalInfo = modalInfo
    },
  },
  actions: {
  },
  modules: {
  }
})
